// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
// require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
window.$ = window.jQuery = require('jquery')
require('corejs-typeahead')
window.Bloodhound = require('corejs-typeahead/dist/bloodhound.min.js')
window.IScroll = require('iscroll')
require('jquery.cookie')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import './drawer.min.js'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '../stylesheets/application'

FontAwesome.config.mutateApproach = 'sync'


$(function() {
  setTimeout(function() {
    $('.flash-alert').fadeOut('slow');
  }, 3000);

  function setPageTopIconRightPosition() {
    var windowWidth = $(window).outerWidth();
    var rightValue = windowWidth < 1140 ? 30 : (windowWidth - 1140) / 2;
    pageTop.css('right', rightValue + 'px');
  }

  function displayPageTopIcon() {
    var googleAd = $('.adsbygoogle.adsbygoogle-noablate').slice(-1);
    var bottomValue = googleAd[0] !== undefined && googleAd.css('bottom') === '0px' ? googleAd.outerHeight() + 30 : 50;
    pageTop.stop().animate({ bottom: bottomValue + 'px', opacity: 1 }, 300);
  }

  function adjustDrawerMenuPosition() {
    var googleAdElement = $('.adsbygoogle.adsbygoogle-noablate').slice(-1)[0];
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutationRecord) {
        $('.drawer-hamburger')[0].style.top = $('.drawer-nav')[0].style.top = parseInt(googleAdElement.style.top) + parseInt(googleAdElement.style.height) + 'px';
      });
    });
    if ($('.adsbygoogle.adsbygoogle-noablate').length && googleAdElement.style.top !== 'auto') {
      observer.observe(googleAdElement, { attributes: true, attributeFilter: ['style']});
    }
  }

  var visible = false;
  var pageTop = $('#page-top');
  setPageTopIconRightPosition();
  $(window).on({
    'load': function() {
      adjustDrawerMenuPosition();
      if ($(this).scrollTop() > 100) {
        displayPageTopIcon();
      }
    },
    'resize': function() {
      setPageTopIconRightPosition();
    },
    'scroll': function() {
      if ($(this).scrollTop() > 100) {
        if (visible === false) {
          visible = true;
          displayPageTopIcon();
        }
      } else {
        if (visible) {
          visible = false;
          pageTop.stop().animate({ bottom: '-50px', opacity: 0 }, 300);
        }

        if ($(this).scrollTop() === 0) {
          $('body').css('padding', 0);
        }
      }
    }
  });

  pageTop.on('click', function() {
    $('html, body').animate({ scrollTop: 0 }, 300);
  });

  $('#drawer-page-top').on('click', function() {
    $('html, body').animate({ scrollTop: 0 }, 300);
  });

  $('#drawer-page-bottom').on('click', function() {
    $('html, body').animate({ scrollTop: $(document).height() - $(window).height() }, 300);
  });
});
